import React from 'react'

const BannerBlog= (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Noticias</h1>
            </header>
            <div className="content">
                <p>Mantente informado sobre nuestro proyecto.</p>
            </div>
        </div>
    </section>
)

export default BannerBlog
